<template>
  <div class="page">
    <div class="left">
      <ul>
        <li>
          <img src="../../assets/image/icon/home.png">
          {{ nav.parent }}
        </li>
        <li v-if="parent1" @click="goBack">
          <img src="../../assets/image/icon/next.png">
          {{ parent1 }}
        </li>
        <li>
          <img src="../../assets/image/icon/next.png">
          {{ nowPage }}
        </li>
      </ul>
    </div>
    <div class="right">
      <ul v-if="nav.show!==false">
        <li v-for="item in nav.children" :key="item.index"
            @click="clickNav(item)">
          <router-link :to="item.to">{{ item.name }}</router-link>
          <div v-if="nowPage===item.name" class="line"></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "SecondNaviBar",
  data() {
    return {}
  },
  props: {
    nav: {},
    nowPage: {
      type: String
    },
    parent1: {
      type: String,
      default: ''
    }
  },
  methods: {
    clickNav(e) {
      this.$emit('getTitle', e.name)
    },
    goBack() {
      let list = this.nav.children
      for (let i = 0; i < list.length; i++) {
        if (this.parent1 === list[i].name) {
          this.$router.push(list[i].to)
          this.$emit('setTitle', {nowPage: this.parent1, parent1: ''})
        }
      }
    }
  }
}
</script>

<style scoped>
.page {
  width: 100%;
  height: 20px;
  font-size: 14px;
  padding: 18px 0 12px 0;
  border-bottom: 1px solid #dfdfdf;
  display: flex;
  justify-content: space-between;
}

.border {
  border-bottom: 2px red solid;
}

ul {
  height: 20px;
  padding: 0;
  margin: 0;
}

ul li {
  line-height: 20px;
  height: 20px;
  float: left;
  padding-bottom: 5px;
  margin: 0 10px 0 0;
  list-style: none;
  display: flex;
  cursor: pointer;
  cursor: hand;
}
.right ul li {
  width: 80px;
  display: block;
}
.right ul li a{
  width: 80px;
  display: block;
  text-align: center;
 /*margin: auto;*/
}
.right ul li .line{
  width: 40px;
  height: 2px;
  background-color: red;
  margin: 5px 20px 0;
}

img {
  width: 20px;
  height: 20px;
}

a {
  color: #050001;
  text-decoration: none;
}
</style>