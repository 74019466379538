<template>
  <div>
    <heard-and-footer v-on:getTitleName="getTitleName">
      <title-name slot="title" :my-name="title"
                  :my-background="require('../../assets/image/huiyuanjianjie.png')"></title-name>
      <div slot="content">
        <second-navi-bar v-on:getTitle="getTitleName" v-on:setTitle="setTitle"
                         :nav="nav" :nowPage="nowPage" :parent1="parent1"></second-navi-bar>
        <router-view  v-on:getTitle="addNav"></router-view>
      </div>
    </heard-and-footer>
  </div>
</template>
<script>
import heardAndFooter from "@/components/public/heardAndFooter";
import TitleName from "@/components/public/TitleName";
import SecondNaviBar from "@/components/public/SecondNaviBar";

export default {
  name: "memberAssociation",
  components: {
    heardAndFooter,
    TitleName,
    SecondNaviBar,
  },
  data() {
    return {
      title: this.$route.query.name,
      nowPage:this.$route.query.name,
      parent1: '',
      nav: {
        parent: '协会简介',
        show:false,
        children: [
          {index: 0, name: '会员简介', to: '/synopsis?name=会员简介'},
          {index: 1, name: '会企供求', to: '/supply?name=会企供求'},
        ]
      },
    }
  },
  methods: {
    getTitleName(val) {
      this.title = val
      this.nowPage = val
    },
    addNav(val) {
      this.parent1 = this.nowPage
      this.nowPage = val
    },
    setTitle(val){
      this.title= this.$route.query.name
      this.nowPage=val.nowPage
      this.parent1=val.parent1
    }
  }
}
</script>

<style scoped>

</style>